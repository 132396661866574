<style>
  #tokenInputField {
    letter-spacing: 42px;
    border: 0;
    width: 320px;
    min-width: 320px;
  }

  #innerTokenInputFieldFrame{
    left: 0;
    position: sticky;
  }

  #outerTokenInputFieldFrame{
    width: 275px;
    overflow: hidden;
  }
  /* Disable increment/decrement buttons. */
  .tokenInput::-webkit-inner-spin-button,
  .tokenInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Disable increment/decrement buttons in Firefox too. */
  .tokenInput {
    -moz-appearance: textfield;
  }
</style>
<script>

import {
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import vueQr from 'vue-qr';
export default {
  props: ['isMobile'],
  components: {
    vueQr
  },
  mounted() {
    this.getQRCodeData();
  },
  data() {
    return {
      token: "",
      qrCodeData: null,
      ready: false,
      error: false,
      logoSrc: 'https://app.cftools.cloud/favicon.ico'
    };
  },
  validations: {
    token: { required, minLength: minLength(6), maxlength: maxLength(6) },
    form: ['token']
  },
  methods: {
    handleError: function (error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async getQRCodeData() {
      try{
        const response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/setup-2fa', {credentials: 'include'});
        if (!response.ok){
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
        const data = await response.json();
        this.qrCodeData = data.provisioning_uri;
        this.ready = true;
      }catch(error){
        this.handleError(error);
      }
    },
    validate() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
  }
}
</script>

<template>
  <div class="row justify-content-evenly">
    <div class="col">
      <div class="row">
        <div :class="(this.isMobile === undefined)?'col-10':'col-6'">
          <b>{{ $t('account.security.2fa.instruction_heading') }}</b>
          <ol style="padding-bottom: 10px; padding-top: 10px">
            <li>
              {{ $t('account.security.2fa.instruction_step_1') }}
              <br/>
              {{ $t('account.security.2fa.instruction_apps_examples') }}
              <ul>
                <li>
                  Google Authenticator
                </li>
                <li>
                  Microsoft Authenticator
                </li>
                <li>
                  Authy
                </li>
              </ul>
            </li>
            <li>
              {{ $t('account.security.2fa.instruction_step_2') }}
            </li>
            <li>
              {{ $t('account.security.2fa.instruction_step_3') }}
            </li>
            <li>
              <b>{{ $t('account.security.2fa.instruction_end') }}</b>
            </li>
          </ol>
          <div class="alert alert-danger" role="alert">
            <h4 style="color: darkred" >
              {{ $t('account.security.2fa.warning.heading') }}
            </h4>
            {{ $t('account.security.2fa.warning.warning_text') }}
          </div>
        </div>
        <div class="form-group col-6" v-if="ready">
          <div>
            <vue-qr
                backgroundColor='transparent'
                colorLight="#A2ACCC"
                colorDark="#2A304A"
                :logoSrc="logoSrc"
                :text="qrCodeData"
                :size="300"
            ></vue-qr>
          </div>
          <label for="outerTokenInputFieldFrame" class="mt-3">
            {{ $t('account.security.2fa.prompt') }}
          </label>
          <div class="mt-2">
            <div id="outerTokenInputFieldFrame">
              <div id="innerTokenInputFieldFrame">
                <input
                    class="form-control tokenInput"
                    :class="{ 'is-invalid': $v.token.$error }"
                    v-model="token"
                    id="tokenInputField"
                    type="number"
                    maxlength="6"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder="000000"
                    onkeypress="if(this.value.length===6) return false;"
                />
                <div v-if="$v.token.$error" class="invalid-feedback">
              <span v-if="!$v.token.required">
                {{ $t('account.security.2fa.process.error.token_required') }}
              </span>
                  <span v-if="!$v.token.minLength">
                {{ $t('account.security.2fa.process.error.token_min_length') }}
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>